function testJS(test) { alert(test); console.log(test); }



function takaisin() {
  window.history.back();
}



jQuery( document ).ready( function() {
  replaceWithSvg();
});





jQuery( window ).scroll( function() {
  if ( jQuery( window ).scrollTop() > 115 ) {
    jQuery('#header').addClass('fixed');
  } else {
    jQuery('#header').removeClass('fixed');
  }

  if ( jQuery( window ).scrollTop() > 116 ) {
    jQuery('#header').addClass('transition');
  } else {
    jQuery('#header').removeClass('transition');
  }

  if ( jQuery( window ).scrollTop() > 665 ) {
    jQuery('#header').addClass('visible');
  } else {
    jQuery('#header').removeClass('visible');
  }
});





/***********************
*   REPLACE WITH SVG   *
***********************/
function replaceWithSvg() {
  jQuery('img.svg').each(function() {
      var $img = jQuery(this);
      var imgID = $img.attr('id');
      var imgClass = $img.attr('class');
      var imgURL = $img.attr('src');
      var imgFill = $img.data('fill');

      jQuery.get(imgURL, function(data) {
          var $svg = jQuery(data).find('svg');
          if( typeof imgID !== 'undefined' ) { $svg = $svg.attr('id', imgID); }
          if( typeof imgClass !== 'undefined' ) { $svg = $svg.attr('class', imgClass+' replaced-svg'); }
          $svg = $svg.removeAttr('xmlns:a');
          if( !$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width') ) { $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width') ) }
          if ( imgFill !== '' ) { $svg.attr('fill', imgFill); }
          $img.replaceWith($svg);
      }, 'xml');

  });
}



/********************
*   SMOOTH SCROLL   *
********************/
(function ($) {
$('a[href*="#"]')
  .not('[href="#"]')
  .not('[href="#0"]')
  .click(function(event) {
    if ( location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname ) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');  
      if (target.length) {
        event.preventDefault();
        var ww = jQuery(window).width(); 
        if (ww <= 959) { 
          $('html, body').animate({
            scrollTop: target.offset().top - 60
          }, 1000);
        } else {
          $('html, body').animate({
            scrollTop: target.offset().top - 120
          }, 1000);
        }
        $(target).focus();
      }
    }
  });
})(window.jQuery);





function toggleOpen(eleID, lockBody, current, main) {
  eleID = '#' + eleID;
  if ( jQuery(eleID).hasClass('open') ) {
    jQuery(current).removeClass('open');
    jQuery(eleID).removeClass('open');
    if (lockBody !== 0) {
      jQuery('body').removeClass('locked' + lockBody);
    }
    if (main !== 0) {
      jQuery('main').removeClass('open');
    }
  } else {
    jQuery(current).addClass('open');
    jQuery(eleID).addClass('open');
    if (lockBody !== 0) {
      jQuery('body').addClass('locked' + lockBody);
    }
    if (main !== 0) {
      jQuery('main').addClass('open');
    }
  }
}


jQuery('main.open').on('click', function(e) {
  e.preventDefault();
  toggleNav();
});

function toggleNav() {
  if (!jQuery('html').hasClass('open')) {
    jQuery('.mobile-nav').addClass('open');
    jQuery('main').addClass('open');
    jQuery('footer').addClass('open');
    jQuery('.toggle__navigation').addClass('open');
    jQuery('html').addClass('open');
    jQuery('html').addClass('locked');
  } else {
    jQuery('.mobile-nav').removeClass('open');
    jQuery('main').removeClass('open');
    jQuery('footer').removeClass('open');
    jQuery('.toggle__navigation').removeClass('open');
    jQuery('html').removeClass('open');
    jQuery('html').removeClass('locked');
  }
}





// ACCESSIBILITY FOR KEYBOARD USE: OPEN MOBILE NAVIGATION
jQuery(".toggle").keyup(function(event) {
  if (event.keyCode === 13) { // 13 => Enter
    jQuery(this).click();
  }
});





function avaaHaitari(ele) {
  jQuery(ele).parent().toggleClass('open');
}

function openTab(ele, nav) {
  jQuery('.single--products__tabs-navigation a').removeClass('current');
  jQuery('.single--product__tab').removeClass('current');
  jQuery(nav).addClass('current');
  jQuery('#tab-' + ele).addClass('current');
}




if (jQuery(".hero__youtube").length > 0) {
  var videoID = jQuery(".hero__youtube").data("video-id");
  var videoYouTubeLink = jQuery(".hero__youtube").data("video-youtube-link");
  var videoStart = jQuery(".hero__youtube").data("video-start");
  var videoEnd = jQuery(".hero__youtube").data("video-end");
  var videoWidthAdd = jQuery(".hero__youtube").data("video-width-add");
  var videoHeightAdd = jQuery(".hero__youtube").data("video-height-add");

  // Create video script element
  var tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/player_api';
  var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  // Prepend expander if data attr is yes
  if (videoYouTubeLink === 'y') {
    jQuery(".hero__youtube").prepend('<a href="https://www.youtube.com/watch?v=' + videoID + '" class="video-expand" target="_blank">View on Youtube</a>');
  }

  // Setup the Youtube TV with player defaults
  var tv, playerDefaults = {autoplay: 0, autohide: 1, modestbranding: 1, rel: 0, showinfo: 0, controls: 0, disablekb: 1, enablejsapi: 0, iv_load_policy: 3};
  var vid = {'videoId': videoID, 'startSeconds': videoStart, 'endSeconds': videoEnd, 'suggestedQuality': 'hd720'};

  function onYouTubePlayerAPIReady(){
    tv = new YT.Player('tv', {events: {'onReady': onPlayerReady, 'onStateChange': onPlayerStateChange}, playerVars: playerDefaults});
  }

  function onPlayerReady(){
    tv.loadVideoById(vid);
    tv.mute();
  }

  function onPlayerStateChange(e) {
    if (e.data === 1){
      jQuery('#tv').addClass('active');
    } else if (e.data === 0){
      tv.seekTo(vid.startSeconds)
    }
  }

  function vidRescale(){
    var w = jQuery(window).width() + videoWidthAdd,
      h = jQuery(window).height() + videoHeightAdd;
    if (w/h > 16/9){
      tv.setSize(w, w/16*9);
      jQuery('.tv .screen').css({'left': '0px'});
    } else {
      tv.setSize(h/9*16, h);
      jQuery('.tv .screen').css({'left': -(jQuery('.tv .screen').outerWidth()-w)/2});
    }
  }

  jQuery(window).on('load resize', function(){
    vidRescale();
  });
}





// JUMP TO UP AFTER PAGER ACTION
jQuery(document).on('facetwp-loaded', function() {
  if (FWP.loaded) {
    var ww = jQuery(window).width();
    if (ww <= 959) { 
      jQuery('html, body').animate({
        scrollTop: jQuery('.facetwp-template').offset().top - 81
      }, 1000);
    } else {
      jQuery('html, body').animate({
        scrollTop: jQuery('.facetwp-template').offset().top - 161
      }, 1000);
    }
  }
});





// MAGNIFIC POPUPS
jQuery('.single--product__hero-images').each(function() {
  jQuery(this).magnificPopup({
      delegate: 'a',
      type: 'image',
      gallery: {
        enabled: true
      }
  });
});

jQuery.extend(true, jQuery.magnificPopup.defaults, {
  tClose: 'Sulje (Esc)',
  tLoading: 'Ladataan...',
  gallery: {
    tPrev: 'Edellinen (Vasen nuoli)',
    tNext: 'Seuraava (Oikea nuoli)',
    tCounter: '%curr% / %total%'
  },
  image: {
    tError: '<a href="%url%">Kuvaa</a> ei voitu ladata.'
  },
  ajax: {
    tError: '<a href="%url%">Sisältöä</a> ei voitu ladata.'
  }
});